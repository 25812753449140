import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    const $dropdowns = $('.sub-nav.sub-nav--dropdown');
    if (!$dropdowns.length) {
        return;
    }

    $dropdowns.each((index, dropdown) => {
        const $dropdown = $(dropdown);
        const $allLinks = $dropdown.find('.sub-nav__link');
        const $subNavList = $dropdown.find('.sub-nav__list');
        $allLinks.each((idx, value) => {
            if (window.location.pathname === $(value).attr('href')) {
                $subNavList.prepend($(value).parent());
            }

            $dropdown.addClass('sub-nav--loaded');
        });
    });

    $dropdowns.each((index, dropdown) => {
        const $dropdown = $(dropdown);
        const $firstLink = $dropdown.find('.sub-nav__list > :first-child > a');
        $firstLink.on('click', (e) => {
            e.preventDefault();
        });
    });
});
