import {
    $, addAction, doAction, INIT,
} from '@situation/setdesign.util';

addAction(INIT, () => {
    $('.email-widget').each(function () {
        const $this = $(this);
        const $modal = $($this.attr('action'));
        const $input = $this.find('.email-widget__input');
        const $emailField = $(`#${$modal.find('label.gfield_label:contains(Email)').attr('for')}`);
        $this.submit((e) => {
            e.preventDefault();
            $emailField.val($input.val());
            doAction('showModal', $modal);
        });
    });
});
