import { addFilter } from '@situation/setdesign.util';

addFilter('media-carousel/owl-settings', (settings) => ({
    ...settings,
    nav: true,
    navText: [
        '<svg><use xlink:href="#icon-arrow-thick"></use></svg>',
        '<svg><use xlink:href="#icon-arrow-thick"></use></svg>',
    ],
}));
